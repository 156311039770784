define("discourse/plugins/discourse-topic-management/discourse/connectors/after-topic-footer-main-buttons/remove-topic", ["exports", "discourse/lib/plugin-api"], function (_exports, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    shouldRender(args, component) {
      const categories = component.siteSettings.discourse_topic_management_categories_remove_button_visible.split("|");
      const destination_category = component.siteSettings.discourse_topic_management_hidden_category_id;
      const currentUser = (0, _pluginApi.withPluginApi)("1.2.0", api => {
        return api.getCurrentUser();
      });
      const userAllowed = currentUser.admin || currentUser.moderator || args.topic.user_id === currentUser.id;
      return destination_category !== "" && categories.includes(args.topic.category_id.toString()) && userAllowed;
    }
  };
});