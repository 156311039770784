define("discourse/plugins/discourse-topic-management/discourse/components/remove-topic", ["exports", "@ember/component", "I18n", "discourse/lib/ajax", "discourse/lib/ajax-error", "@ember/service", "discourse/lib/url", "@ember/template-factory"], function (_exports, _component, _I18n, _ajax, _ajaxError, _service, _url, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{d-button
    action=(action "removeTopic")
    class="btn-danger"
    icon="box-archive"
    title="discourse_topic_management.topic.title"
  }}
  */
  {
    "id": "XXIlslto",
    "block": "[[[1,[28,[35,0],null,[[\"action\",\"class\",\"icon\",\"title\"],[[28,[37,1],[[30,0],\"removeTopic\"],null],\"btn-danger\",\"box-archive\",\"discourse_topic_management.topic.title\"]]]]],[],false,[\"d-button\",\"action\"]]",
    "moduleName": "discourse/plugins/discourse-topic-management/discourse/components/remove-topic.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend({
    dialog: (0, _service.inject)(),
    tagName: "span",
    actions: {
      removeTopic() {
        const topic = this.topic;
        const topicId = topic.id;
        const categoryId = topic.category_id;
        this.dialog.yesNoConfirm({
          message: _I18n.default.t("discourse_topic_management.topic.delete_confirm"),
          didConfirm: () => {
            (0, _ajax.ajax)(`/move_topic_to_hidden_category`, {
              method: "POST",
              data: {
                topic_id: topicId
              }
            }).then(() => {
              _url.default.routeTo(`/c/${categoryId}`);
            }).catch(error => {
              (0, _ajaxError.popupAjaxError)(error);
            });
          }
        });
      }
    }
  }));
});